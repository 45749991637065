import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Navi from "../Navi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Images from "../images/code.avif";
import Row from "react-bootstrap/Row";
import { NavLink } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Images2 from "../images/sparkedinnovation.png";
import Image3 from "../images/tobdevs.jpg";
import Image4 from "../images/download.jpg"
import Work from "./Work";
import { Link } from "react-scroll";
import {
  FaDownload,
  FaHtml5,
  FaCss3,
  FaJsSquare,
  FaBootstrap,
  FaReact,
  FaNodeJs,
  FaPersonBooth,
} from "react-icons/fa";
import { DiJqueryLogo } from "react-icons/di";
import { SiExpress, SiMongodb, SiGraphql, SiHeroku } from "react-icons/si";
import { IoIosPerson } from "react-icons/io";

function About() {
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("resumee.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Resume.pdf";
        alink.click();
      });
    });
  };

  const [key, setKey] = useState("about");
  function tabsbutton() {
    setKey("skills");
  }
  function tabsbutton2() {
    setKey("experience");
  }
  return (
    <div name="about" className="aboutpage">
      <Container>
        <Navi></Navi>
        <div style={{ textAlign: "center", marginBottom: "10vh" }}>
          <IoIosPerson style={{ fontSize: "5vh" }} />
        </div>

        <div>
          <Tabs
            defaultActiveKey={key}
            id="fill-tab-example"
            className="cont"
            fill
          >
            <Tab eventKey="about" title="ABOUT" className="about">
              <Row>
                <Col className="imgcol">
                  <img src={Images} alt="" className="image" />
                </Col>
                <Col className="colss" md={8}>
                  <h2>
                    <p className="name">Patrick Obi</p> - Full Stack Developer
                  </h2>
                  <br />
                  <p className="fits">
                    Hi there! My name is Ifeanyichukwu Patrick Obi, and I am a
                    Full-stack developer with a passion for creating amazing web
                    applications! With years of experience in the industry, I
                    have had the opportunity to develop my skills in both
                    front-end and back-end development. I am constantly striving
                    to improve my knowledge in this exciting field, and I'm
                    always up for a good challenge. One thing that sets me apart
                    from others is my ability to problem-solve. I take pride in
                    my work, and I love nothing more than tackling a tough
                    challenge and finding a creative solution.In my opinion,
                    effective communication is vital in fostering successful
                    collaboration, and I have a reputation for being a keen
                    listener and a valuable team player. I graduated with a
                    computer science degree from Carleton University, and I have
                    been working hard ever since to hone my skills and gain
                    practical experience. When I'm not working or studying, I
                    love to stay active by playing basketball or spending time
                    with my family. I also enjoy coding in my free time,
                    participating in hackathons and weekly coding challenges.
                    I'm excited to connect with new people and explore
                    opportunities to work together. Thanks for taking the time
                    to get to know me, and I look forward to hearing from you
                    soon!
                  </p>
                  <button onClick={onButtonClick} className="button2">
                    Download CV <FaDownload />
                  </button>
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey="skills"
              title="SKILLS"
              className="about"
              onClick={tabsbutton}
            >
              <Container>
                <Row>
                  <Col>
                    <h1 className="head2">FRONT END </h1>
                    <ul>
                      <li>
                        <p>
                          <FaHtml5 className="icons" /> HTML5
                        </p>
                      </li>
                      <li>
                        <p>
                          <FaCss3 className="icons" /> Css
                        </p>
                      </li>
                      <li>
                        <p>
                          <DiJqueryLogo className="icons" /> jQuery
                        </p>
                      </li>
                      <li>
                        <p>
                          <FaBootstrap className="icons" /> Bootstrap
                        </p>
                      </li>
                      <li>
                        <p>
                          <FaReact className="icons" /> React
                        </p>
                      </li>
                    </ul>
                  </Col>
                  <Col>
                    <h1 className="head2">BACK END </h1>
                    <ul>
                      <li>
                        <p>
                          <FaNodeJs className="icons" /> Node.js
                        </p>
                      </li>
                      <li>
                        <p>
                          <SiExpress className="icons" /> Express.js
                        </p>
                      </li>
                      <li>
                        <p>
                          <SiMongodb className="icons" /> MongoDb
                        </p>
                      </li>
                      <li>
                        <p>
                          <SiGraphql className="icons" /> GraphQL
                        </p>
                      </li>
                      <li>
                        <p>
                          <SiHeroku className="icons" /> Heroku
                        </p>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab
              eventKey="work"
              title="WORK"
              className="about"
              onClick={tabsbutton2}
            >
              <Container className="mainwork">
                <Row>
                  <Col className="works" xs={9}>
                    <h1>Full Stack Developer</h1>
                    <p>Sparked Innovations, May 2022 - June 2023</p>
                  </Col>
                  <Col xs={3}>
                    <img
                      src={Images2}
                      alt="sparkedInnovation"
                      className="worksimg"
                    />
                  </Col>
                </Row>
                <br />
                
                <Row>
                  <Col className="works" xs={9}>
                    <h1>Customer Support Representative</h1>
                    <p>Solink, November 2023 - Present</p>
                  </Col>
                  <Col xs={3}>
                    <img src={Image4} alt="Solink" className="worksimg" />
                  </Col>
                </Row>
                <div className="skillss">
                  <NavLink to="/work" className="navvi">
                    <button className="button3">VIEW PROJECTS</button>
                  </NavLink>
                </div>
              </Container>
            </Tab>
          </Tabs>
        </div>
      </Container>
    </div>
  );
}

export default About;
