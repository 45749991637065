import React, { useRef, useState } from "react";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navi from "../Navi";

import { BiCodeBlock } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import { AiFillGithub } from "react-icons/ai";

import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { DiGithub } from "react-icons/di";

function Contact() {
  const [show, setShow] = useState(false);
  const [texts, setTexts] = useState();
  const [name, setName] = useState();
  const [website, setWebsite] = useState();
  const [code, setCode] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setName("Sparked Innovation");
    setTexts(
      "Developed a full stack web application for a company called 'Sparked Innovations', utilizing tools such as JQuery, HTML, CSS, and more. This project showcases their range of services while establishing their brand in the digital space. By effectively highlighting their offerings and company ethos, the project impressed the client, delivering measurable results that aligned with their business objectives.."
    );
    setWebsite("https://shop.sparkedinnovations.com/");
  };
  const handleShow2 = () => {
    setShow(true);
    setName("Next Generation");
    setTexts(
      "Created a full stack web application that showcases my skills and expertise as a web developer. The project was built using a range of technologies including HTML, CSS, and JavaScript, and features a range of functionality that reflects my passion for web development. Through this project, I was able to demonstrate my ability to design and build a functional and visually appealing web application while improving my skills in the process."
    );
    setWebsite("https://nxtgeneration.netlify.app/");
  };

  return (
    <div className="workPage">
      <Navi></Navi>
      <Container>
        <div style={{ textAlign: "center", marginBottom: "10vh" }}>
          <BiCodeBlock style={{ fontSize: "5vh" }} />

          <p>
            View some significant project that highlight my technical expertise
          </p>
        </div>
        <Row>
          <Col md={4}>
            <div onClick={handleShow} className="cards"></div>
            <Modal
              size="lg"
              show={show}
              onHide={handleClose}
              className="modall"
            >
              <Modal.Header closeButton>
                <Modal.Title>{name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{texts}</Modal.Body>
              <Modal.Footer>
                <a href={website} target="_blank">
                  <Button>
                    <CgWebsite style={{ fontSize: "5vh" }} /> Live Project
                  </Button>
                </a>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col md={4}>
            <div onClick={handleShow2} className="cards2"></div>
            <Modal
              size="lg"
              show={show}
              onHide={handleClose}
              className="modall"
            >
              <Modal.Header closeButton>
                <Modal.Title>{name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{texts}</Modal.Body>
              <Modal.Footer>
                <a href={website} target="_blank">
                  <Button>
                    <CgWebsite style={{ fontSize: "5vh" }} /> Live Project
                  </Button>
                </a>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>

        <div className="skillss">
          <NavLink to="/contact" className="navvi">
            <button className="button3">GET IN TOUCH</button>
          </NavLink>
        </div>
      </Container>
    </div>
  );
}

export default Contact;
